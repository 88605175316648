import React, { Profiler, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import MainContext from 'MainContext';
// import ProfileModal  from './profileModal';
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 90%;
  margin: 0 auto;
`;

const Storage = styled.div`
  position: absolute;
  bottom: 2.6vw;
  left: 50%;
  transform: translateX(-50%);
  width: 9.255vw;
  height: 8.465vw;
  border-radius: 0.937vw;
  background-color: ${(props) => props.theme.cardOnCardBackground};
  display: flex;
  flex-direction: column;
  padding: 0.78vw;
  gap: 1vw;
`;

const Title = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-size: 0.728vw;
  font-weight: 500;
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
`;

const Bar = styled.div`
  background-color: ${(props) => props.theme.textPrimary};
  width: 100%;
  height: 0.832vw;
  border-radius: 0.832vw;
  overflow: hidden;
  position: relative;
`;

const UsedBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${(props) => {
    if (props.width >= 95) return props.theme.redColor;
    if (props.width >= 80) return props.theme.yellowColor;
    return props.theme.blueColorPrimary;
  }};
  width: ${(props) => props.width + '%'};
  height: 0.832vw;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 14vw;
  left: 50%;
  transform: translateX(-50%);
`;

const BarDescription = styled.div`
  color: ${(props) => props.theme.textGrey};
  font-size: 0.631vw;
  font-weight: 400;
  margin-left: 0.2vw;
`;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
`;

const Tab = (props) => {
  const { theme } = useContext(ThemeContext);
  const { profile } = props;
  const { getUserUploadSizeContext, usedSize, familySize

  } = useContext(MainContext).login;
  const [uploadSize, setUploadSize] = useState(0);
  const [totalUploadSize, setTotalUploadSize] = useState(0);
  // const [profileInfoModal, setProfileInfoModal] = useState(false);
  let history = useHistory();

  const onUpgradeClick = () => { };
  
  useEffect(() => {
    if (usedSize) {
      
      setUploadSize(usedSize)

      if (uploadSize >= 1000000000) {
        setTotalUploadSize(uploadSize / 1000000000);
      }
      if (uploadSize < 1000000000) {
        setTotalUploadSize(uploadSize / 1000000);
      }
    }
  }, [profile, getUserUploadSizeContext])

  const onStorageClick = () => { 
    // setProfileInfoModal(!profileInfoModal)
    // /plans
    history.push("/plans");
    // console.log("2222", profile, profileInfoModal)
  }

  const storagePercentage = Math.min((uploadSize * 100) / familySize, 100);
  const orderCountPercentage = Math.min((profile?.orderCount * 100) / profile?.adminPlanCycle?.order_limitation, 100);

  const hasOrderData = profile?.orderCount !== undefined && profile?.adminPlanCycle?.order_limitation !== undefined;
  const hasStorageData = uploadSize !== undefined && familySize !== undefined;

  return (
    <Wrapper theme={theme}>
      <ButtonWrapper>
        <Link style={{ textDecoration: 'none' }} to="/plans">
          <Button onClick={onUpgradeClick} marginTop={0} text="UPGRADE" size="medium" />
        </Link>
      </ButtonWrapper>
      <Storage theme={theme} onClick={onStorageClick}>
        <MetricContainer>
          <Title theme={theme}>Orders</Title>
          <BarContainer>
            <Bar theme={theme}>
              <UsedBar width={orderCountPercentage} theme={theme} />
            </Bar>
            {hasOrderData && (
              <BarDescription theme={theme}>
                {`${profile?.orderCount} out of ${profile?.adminPlanCycle?.order_limitation}`}
              </BarDescription>
            )}
          </BarContainer>
        </MetricContainer>

        <MetricContainer>
          <Title theme={theme}>Storage</Title>
          <BarContainer>
            <Bar theme={theme}>
              <UsedBar width={storagePercentage} theme={theme} />
            </Bar>
            {hasStorageData && (
              <BarDescription theme={theme}>
                {`${Math.round(totalUploadSize * 10) / 10} ${uploadSize >= 1000000000 ? "GB" : "MB"} of ${(familySize / 1000000000)} GB`}
              </BarDescription>
            )}
          </BarContainer>
        </MetricContainer>
      </Storage>
      {/* <ProfileModal
        open={profileInfoModal}
        toggle={setProfileInfoModal}
        profile = {profile}
        uploadSize ={uploadSize}
        setUploadSize = {setUploadSize}
        totalUploadSize={totalUploadSize}
        setTotalUploadSize ={setTotalUploadSize}
        familySize = {familySize}
      /> */}
    </Wrapper>
  );
};

export default Tab;
