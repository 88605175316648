import React, { Suspense, lazy, useState, useEffect } from 'react';
import axios from 'axios';

import AuthRequired from 'containers/auth/auth-required';
import RedirectAuthorized from 'containers/auth/redirect-authorized';
import PermisstionCheck from 'containers/auth/permisstion-check';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Background from 'containers/background';

import { CounterContextProvider } from 'context/bestContext';
import { ThemeContextProvider } from 'context/themeContext';
import { BackgroundContextProvider } from 'context/backgroundContext';
import AppProvider from './AppProvider';

import Navbar from 'containers/navBar';
import Sidebar from 'containers/sideBar';
import Drawer from 'containers/drawer';
import Toaster from 'components/toaster';
import Boarding from 'containers/boarding';
import VersionModal from 'containers/version-updates-modal';

import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { LazyLoadingComponent } from 'components/wrapper';

import { handleLazyLoadError } from 'utils';

const Login = lazy(() => import('containers/login').catch(handleLazyLoadError));
const OTP = lazy(() => import('containers/otp').catch(handleLazyLoadError));
const Dashboard = lazy(() => import('containers/dashboard').catch(handleLazyLoadError));
const Orders = lazy(() => import('containers/orders').catch(handleLazyLoadError));
const Conversations = lazy(() => import('containers/conversations').catch(handleLazyLoadError));
const PdfInvoiceGenerate = lazy(() => import('containers/orders/components/pdf-invoice-generate').catch(handleLazyLoadError));
const Discounts = lazy(() => import('containers/discounts').catch(handleLazyLoadError));
const Factories = lazy(() => import('containers/factories').catch(handleLazyLoadError));
const Materials = lazy(() => import('containers/materials').catch(handleLazyLoadError));
const Printers = lazy(() => import('containers/printers').catch(handleLazyLoadError));
const Plans = lazy(() => import('containers/plans').catch(handleLazyLoadError));
const Users = lazy(() => import('containers/users').catch(handleLazyLoadError));
const UserProfile = lazy(() => import('containers/user-profile').catch(handleLazyLoadError));
const Settings = lazy(() => import('containers/settings').catch(handleLazyLoadError));
const Slicer = lazy(() => import('containers/slicer').catch(handleLazyLoadError));
const Checkout = lazy(() => import('containers/checkout').catch(handleLazyLoadError));
const Bascket = lazy(() => import('containers/bascket').catch(handleLazyLoadError));
const UserNavbar = lazy(() => import('containers/user-navBar').catch(handleLazyLoadError));
const CompanyProfileForm = lazy(() => import('containers/company-profile-form').catch(handleLazyLoadError));
  
const google_auth_client_id = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
const App = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-P5L3588'
  };

  TagManager.initialize(tagManagerArgs);


  // useEffect(() => {
  axios.interceptors.response.use(
    (response) => response,
    function (error) {
      const originalRequest = error.config;
      if (
        (error.response.status === 401 || error.response.status === 500) &&
        originalRequest.url ===
          `${process.env.REACT_APP_AUTHENTICATION_SERVICE_URL}/login/get/access-token`
      ) {
        // router.push('/login');
        return Promise.reject(error);
      }

      // if (error.response.status === 401 && !originalRequest._retry) {
      if (
        (error.response.status === 401 || error.response.status === 500) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return axios
          .post(`${process.env.REACT_APP_AUTHENTICATION_SERVICE_URL}/login/get/access-token`, {
            refresh_token: 'Bearer ' + localStorage.getItem('token')
          })
          .then(async (res) => {
            axios.defaults.headers.common['Authorization'] =
              (await 'Bearer ') + res.data.payload.access_token;
            originalRequest.headers['Authorization'] = 'Bearer ' + res.data.payload.access_token;
            return axios(originalRequest);
          });
      }
      return Promise.reject(error);
    }
  );
 
  //these lines are added by qazal
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.onload = () => {
      setIsLoading(false);
    };
  }, []);

  return (
    // <Suspense fallback={<div>Loading...</div>}>
    <>
    <ThemeContextProvider>
      {isLoading ? ( //this is added by qazal for having a loading at first time
        <LazyLoadingComponent display='flex'/>
      ) : (
        <Suspense fallback={<LazyLoadingComponent display='flex'/>}>
          <AppProvider>
            <Router basename="/admin">
              <CounterContextProvider>
                  <BackgroundContextProvider>
                    <GoogleOAuthProvider clientId={google_auth_client_id}> 
                      <Background> 
                        
                        <Suspense
                          fallback={
                            <LazyLoadingComponent
                              display="flex"
                              style={{
                                backgroundColor: 'red',
                                fontSize: '2em',
                                color: 'red',
                                fontWeight: 'bold'
                              }}
                            />
                          }
                        >
                          <Switch>
                            <Route
                              exact
                              path="/"
                              render={() => (
                                <RedirectAuthorized redirect="/dashboard">
                                  <Login />
                                </RedirectAuthorized>
                              )}
                            />
                            <Route
                              exact
                              path="/otp"
                              render={() => (
                                <RedirectAuthorized redirect="/dashboard">
                                  <OTP />
                                </RedirectAuthorized>
                              )}
                            />
                            <Route
                              exact
                              path="/company-profile-form"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <CompanyProfileForm />
                                </AuthRequired>
                              )}
                            /> 
                            <Route
                              exact
                              path="/dashboard"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <Dashboard />
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/orders"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck
                                    access="get-invoices"
                                    redirect="/dashboard"
                                    isHubAlload={true}
                                  >
                                    <Orders />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            
                            {/* ================= new route for invoice ============*/}
                            <Route
                              exact
                              path="/orders/invoice/:invoiceId"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck
                                    access="get-invoices"
                                    redirect="/dashboard"
                                    isHubAlload={true}
                                  >
                                    <Orders/>
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            {/* ================= new route for invoice ============*/}



                             {/* ================= new route for  pdf invoice generate ============*/}
                             {<Route
                                exact
                                path="/orders/invoice/:invoiceId/pdf"
                                render={() => (
                                  <AuthRequired redirect="/">
                                    <PermisstionCheck
                                      access="get-invoices"
                                      redirect="/dashboard"
                                      isHubAlload={true}
                                    >
                                      <PdfInvoiceGenerate />
                                    </PermisstionCheck>
                                  </AuthRequired>
                                )}
                              /> }
                             {/* ================= new route for  pdf invoice generate ============*/}


                            <Route
                              exact
                              path="/orders/new-order"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck
                                    access="get-invoices"
                                    redirect="/dashboard"
                                    isHubAlload={true}
                                  >
                                    <Orders newOrderStatus={true} />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/orders/new-order/:userId"
                              render={(props) => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="post-invoices" redirect="/dashboard">
                                    <Slicer userId={props.match.params.userId} />
                                    <Bascket userId={props.match.params.userId} />
                                    <UserNavbar />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/orders/new-order/:userId/checkout"
                              render={(props) => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="post-invoices" redirect="/dashboard">
                                    <Checkout userId={props.match.params.userId} />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/conversations"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="get-invoices" redirect="/dashboard">
                                    <Conversations />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/discounts"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="not-allowed" redirect="/dashboard">
                                    <Discounts />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/workshops"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="get-hubs" redirect="/dashboard">
                                    <Factories />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/workshops/new-workshops"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="get-hubs" redirect="/dashboard">
                                    <Factories selectedHub={-1} />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/tech-and-materials"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="get-materials" redirect="/dashboard">
                                    <Materials />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/workshops/:hubId"
                              render={(props) => (
                                <AuthRequired redirect="/">
                                  <Factories hubId={props.match.params.hubId} />
                                </AuthRequired>
                              )}
                            />

                            {/* ================= new route for workshop orders ============*/}
                            <Route
                              exact
                              path="/workshops/:hubId/invoice/:invoiceId"
                              render={(props) => (
                                <AuthRequired redirect="/">
                                  <Factories hubId={props.match.params.hubId}/>
                                </AuthRequired>
                              )}
                            />
                            {/* ================= new route for workshop orders ============*/}
                            
                            <Route
                              exact
                              path="/printers"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <Printers />
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/plans"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <Plans />
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/users"
                              render={() => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="get-users" redirect="/dashboard">
                                    <Users display={true} />
                                    <UserProfile display={false} />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              exact
                              path="/user-profile/:userId"
                              render={(props) => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck access="get-users" redirect="/dashboard">
                                    <Users display={false} />
                                    <UserProfile display={true} userId={props.match.params.userId} />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                            <Route
                              // exact
                              path="/settings"
                              render={(props) => (
                                <AuthRequired redirect="/">
                                  <PermisstionCheck
                                    access="not-allowed"
                                    redirect="/dashboard"
                                    isHubAlload={true}
                                  >
                                    <Settings match={props.match} />
                                  </PermisstionCheck>
                                </AuthRequired>
                              )}
                            />
                          </Switch>
                        <Navbar hideOnUrls={['/company-profile-form']} />
                        <Sidebar />
                        <Drawer />
                        <Toaster />
                        <Boarding />
                        </Suspense>
                        <VersionModal />
                      </Background>
                    </GoogleOAuthProvider>
                  </BackgroundContextProvider>
              </CounterContextProvider>
            </Router>
          </AppProvider>
        </Suspense>
      )}
      </ThemeContextProvider>
    </>
  );
};

export default App;
