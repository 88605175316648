import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import Modal from 'components/modal';
import Button from 'components/button';
import { fetchVersionUpdate, markVersionAsSeen } from './api';

const ContentWrapper = styled.div`
  padding: 2vw;
  color: white;
  background: linear-gradient(135deg, #2563eb, #1e40af);
  border-radius: 1vw;
  min-width: 35vw;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 1.5vw;
  top: 1.5vw;
  cursor: pointer;
  font-size: 1.5vw;
  color: white;
  opacity: 0.8;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 1;
  }
`;

const Title = styled.h2`
  font-size: 1.5vw;
  margin-bottom: 1.5vw;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5vw;
`;

const Content = styled.div`
  margin: 2vw 0;
  font-size: 1vw;
  line-height: 1.6;
  color: white;

  h3 {
    font-size: 1.2vw;
    margin-bottom: 1vw;
    color: white;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    margin-bottom: 1vw;
    display: flex;
    align-items: flex-start;
    
    &:before {
      content: "•";
      margin-right: 0.8vw;
      flex-shrink: 0;
      color: white;
    }

    strong {
      color: white;
      font-weight: 600;
      margin-right: 0.3vw;
      display: inline;
    }

    span {
      flex: 1;
    }
  }

  p {
    margin: 1vw 0;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2vw;
`;

const ScheduleButton = styled(Button)`
  background: white;
  color: #2563eb;
  font-weight: 600;
  border-radius: 0.4vw;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

const VersionModal = () => {
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const [versionUpdates, setVersionUpdates] = useState([]);

  useEffect(() => {
    checkVersionUpdates();
  }, []);

  const checkVersionUpdates = async () => {
    try {
      const result = await fetchVersionUpdate();
      if (result.payload.updates.length > 0) {
        setVersionUpdates(result.payload.updates);
        setIsOpen(true);
      }
    } catch (error) {
      console.error('Error checking version updates:', error);
    }
  };

  const handleClose = async () => {
    try {
      await markVersionAsSeen();
      setIsOpen(false);
    } catch (error) {
      console.error('Error marking version as seen:', error);
    }
  };

  const onScheduleMeeting = () => {
    window.open('https://calendly.com/layers-app/introducing-layers-app', '_blank');
  };

  const content = versionUpdates.length > 0 ? versionUpdates[versionUpdates.length - 1].html : '';

  return (
    <Modal open={isOpen} toggle={handleClose}>
      <ContentWrapper>
        <CloseButton onClick={handleClose}>✕</CloseButton>
        <Title>🚀 What's New in Layers.app?</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
        <ButtonWrapper>
          <ScheduleButton
            text="Schedule a Meeting"
            onClick={onScheduleMeeting}
            width={15}
            height={2.5}
            fontSize={0.833}
          />
        </ButtonWrapper>
      </ContentWrapper>
    </Modal>
  );
};

export default VersionModal; 