import axios from 'axios';
const { REACT_APP_AUTHENTICATION_SERVICE_URL } = process.env;

export const fetchVersionUpdate = async () => {
  try {
    const response = await axios.get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/versioning`);
    return response.data;
  } catch (error) {
    console.error('Error fetching version update:', error);
    throw error;
  }
};

export const markVersionAsSeen = async () => {
  try {
    const response = await axios.post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/versioning/seen`);
    return response.data;
  } catch (error) {
    console.error('Error marking version as seen:', error);
    throw error;
  }
}; 