import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'context/themeContext';
import Button from 'components/button';
import { Wrapper, ImageWrapper, Step6Icon, Content, Title, Text, SiteLink } from './style';
import { Link } from 'react-router-dom';
import MainContext from 'MainContext';

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, businessName, setBoardingOpen } = props;
  const { setBoardingState } = useContext(MainContext).boarding;
  const onFinishClick = () => {
    setBoardingOpen(false);
  };
  useEffect(() => {
    setBoardingState(6);
  }, []);
  return (
    <Wrapper display={display}>
      <ImageWrapper theme={theme}>
        <Step6Icon />
      </ImageWrapper>
      <Content>
        <Title theme={theme}>Your online 3D print shop are ready now.</Title>
        <Text theme={theme}>
          Hooray. Your online store is now available. Your customers can upload their 3D file and
          place a 3D print order by visiting:
        </Text>
        <SiteLink theme={theme} onClick={onFinishClick}>
          <Link
            target="_blank"
            href={`https://${businessName}.3dlayers.app`}
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
          >
            {businessName}.3dlayers.app
          </Link>
        </SiteLink>
        <SiteLink>
          <a
            target="_blank"
            href={`https://${businessName}.3dlayers.app`}
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
          >
            <Button onClick={onFinishClick} text="LAUNCH YOUR SHOP 🚀" />
          </a>
        </SiteLink>
      </Content>
    </Wrapper>
  );
};

export default Step1;
